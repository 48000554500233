

import React, { useState } from 'react'
import Logo from '../../assets/logo.png'
import './Header.css'
import bars from '../../assets/bars.png'

import { Link } from 'react-scroll'
export const Header = () => {

    const mobile = window.innerWidth <= 850 ? true : false
    const [menu, setMenu] = useState(false)




    return (
        <div className='header'>
            <img  className='logo' src={Logo} alt="" />
            {menu === false && mobile === true
                ?
                <img onClick={() => setMenu(true)} className='bars' src={bars} alt="" />
                :
                <ul>
                    <li >
                        <Link
                            onClick={() => setMenu(false)}
                            to={'Hero'}
                            spy={true}
                            smooth={true}
                        >Home
                        </Link>
                    </li>
                    <li onClick={() => setMenu(false)}>
                        <Link
                            onClick={() => setMenu(false)}
                            to={'Programs'}
                            spy={true}
                            smooth={true}
                        >Programs
                        </Link>
                    </li>
                    <li onClick={() => setMenu(false)}>
                        <Link
                            onClick={() => setMenu(false)}
                            to={'Reasons'}
                            spy={true}
                            smooth={true}
                        >Reasons
                        </Link>
                    </li>
                    <li onClick={() => setMenu(false)}>
                        <Link
                            onClick={() => setMenu(false)}
                            to={'Plans'}
                            spy={true}
                            smooth={true}
                        >Plans
                        </Link>
                    </li>
                    <li onClick={() => setMenu(false)}>
                        <Link
                            onClick={() => setMenu(false)}
                            to={'Testimonials'}
                            spy={true}
                            smooth={true}
                        >Testimonials
                        </Link>
                    </li>
                </ul>
            }
        </div>
    )
}
